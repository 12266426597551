import { render, staticRenderFns } from "./comunicado.vue?vue&type=template&id=37db38c4&"
import script from "./comunicado.vue?vue&type=script&lang=js&"
export * from "./comunicado.vue?vue&type=script&lang=js&"
import style0 from "./comunicado.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js"
import QPage from 'quasar/src/components/page/QPage.js'
import QForm from 'quasar/src/components/form/QForm.js'
import QInput from 'quasar/src/components/input/QInput.js'
import QSelect from 'quasar/src/components/select/QSelect.js'
import QItem from 'quasar/src/components/item/QItem.js'
import QItemSection from 'quasar/src/components/item/QItemSection.js'
import QItemLabel from 'quasar/src/components/item/QItemLabel.js'
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js'
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js'
import QBtn from 'quasar/src/components/btn/QBtn.js'
import QDialog from 'quasar/src/components/dialog/QDialog.js'
qInstall(component, 'components', {QPage,QForm,QInput,QSelect,QItem,QItemSection,QItemLabel,QOptionGroup,QCheckbox,QBtn,QDialog})
